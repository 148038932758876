<template>
  <div>
    <heads :more="showMore" :msg="title_msg" :states="1"></heads>
    <div class="main">
      <div class="msg">
        <p>
          学员
          <span class="msgName">{{ real_name }}</span>,身份证号
          <span class="msgIdCard">{{ IDnumber }}</span>于
          <span class="msgDate">{{ start_time }}</span>至
          <span class="msgDate1">{{ end_time }}</span>期间，完成了
          <span>{{ certificate_name }}</span>课程全部内容的学习，考核成绩合格，准予结业。
        </p>
      </div>
      <div class="main_foot">
        <dl>
          <dt>
            <img class="ewmImg" :src="code_url" alt>
          </dt>
          <dd>
            <span>扫码查看</span>
          </dd>
        </dl>
        <div class="msginfo">
          <p>证书编号：{{ zsbh }}</p>
          <p>发证日期：{{ make_time }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="down">
      <button class="mybtn" @click="downs()">下载证书</button>
    </div> -->
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { get_img, get_infos } from '@/api/credential.js'
export default {
  components: {
    heads
  },
  data() {
    return {
      id: this.$route.query.id || '',
      isshow: false,
      title_msg: '查询结果',
      make_time: '',
      zsbh: '',
      certificate_name: '',
      real_name: '',
      IDnumber: '',
      start_time: '',
      end_time: '',
      update_time: '',
      code_url: '',
      showMore: true
    }
  },
  mounted() {
    this.getMsg()
  },
  methods: {
    getMsg() {
      var params = {
        id: this.id
      }
      get_infos(params).then(res => {
        console.log(res)
        this.zsbh = res.result.certificate_no
        this.certificate_name = res.result.certificate_name
        this.real_name = res.result.real_name
        this.IDnumber = res.result.IDnumber
        this.start_time = res.result.start_time
        this.end_time = res.result.end_time
        this.make_time = res.result.make_time
        this.code_url = res.result.code_url
      })
    },
    downs() {
      var params = {
        id: this.$route.query.id,
        type: 'down'
      }
      get_img(params).then(res => {
        console.log(res)
      })
    },
    clicks() {
      if (this.isshow === false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    }
  }
}
</script>

<style lang="scss">
.down {
  width: 100%;
  padding-bottom:40px;
}
.mybtn {
  display: block;
  width: 300px;
  height: 65px;
  background: #ee2e2e;
  color: #fff;
  margin: 0 auto;
  margin-top: 100px;
}
.msginfo {
  margin: 10px 0 0 20px;
  p:nth-child(2) {
    padding: 15px 0 0 0;
  }
}
dl span {
  text-align: cecnter;
}
.ewmImg {
  width: 100px;
  height: 100px;
  // background: aqua;
}
.main_foot {
  width: 100%;
  display: flex;
  margin: 160px 0 0 110px;
}
.msg {
  margin: 440px 0 0 0;
  width: 100%;
  p {
    color: #333333;
    width: 72%;
    margin: 0 auto;
    text-indent: 2em;
    line-height: 50px;
  }
}
.main {
  overflow: hidden;
  width: 100%;
  height: 1100px;
  background: url("https://newoss.zhulong.com/forum/202108/19/17/1629365119613678.jpg");
  background-size: 100% 100%;
}
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 58px;
  z-index: 1;
}
</style>
